'use strict';

/* exported
 couponRefundCtrl
  */

function couponRefundCtrl($scope, coupons, appConfig, $uibModal, $state, CurrenciesService, Restangular ) {
  $scope.coupons = coupons;
  $scope.currenciesService = CurrenciesService;
  $scope.appConfig = appConfig;
  $scope.filters = {};
  $scope.total = coupons.meta.total;
  $scope.limit = 20;
  $scope.offset = 0;

  /**
   * filters
   */
  $scope.pageChanged = function() {
    var offset = ($scope.currentPage - 1) * $scope.limit;

    $scope.filters.offset = offset;
    $scope.filters.limit = $scope.limit;

    $scope.coupons.getList($scope.filters).then(function(coupons) {
      $scope.coupons = coupons;
    });
  };

  function updateFiltersAndFetchCoupons(filterKey, data) {
    $scope.filters[filterKey] = data;
    $scope.filters.offset = $scope.offset;
    $scope.filters.limit = $scope.limit;

    $scope.coupons.getList($scope.filters).then(function(coupons) {
      $scope.currentPage = 1;
      $scope.coupons = coupons;
      $scope.total = coupons.meta.total;
    });
  }

  $scope.refundCoupon = function (coupon, includeFees = true) {
    $scope.isLoading = true;
    coupon.includeFees = includeFees;
    Restangular.restangularizeElement(
      null,
      coupon,
      `/refund-coupons`
    )
      .put().then(function(refundedCoupon) {
      $scope.$emit('notify', {type: 'success', title: 'Delete', message: 'Coupon was refund successfully'});
      $scope.isLoading = false;
      $scope.pageChanged();
    })
    .catch(function() {
      $scope.$emit('notify', {type: 'error', title: 'Delete', message: 'Coupon refund failed'});
      $scope.isLoading = false;
    });
  };

  $scope.$on('filters.status', function(event, data) {
    updateFiltersAndFetchCoupons('activated', data);
  });

  $scope.$on('filters.code', function(event, data) {
    updateFiltersAndFetchCoupons('code', data);
  });

  $scope.$on('filters.email', function(event, data) {
    updateFiltersAndFetchCoupons('email', data);
  });

  $scope.$on('filters.bookingReference', function(event, data) {
    updateFiltersAndFetchCoupons('bookingReference', data);
  });
}

'use strict';

/* exported
  menuCouponsRefundCtrl
 */
function menuCouponsRefundCtrl($scope, $rootScope, $location) {
  $scope.filters = {};

  $scope.filterByStatus = function(status) {
    if ($scope.filters.status === status) {
      $scope.filters.status = null;
    } else {
      $scope.filters.status = status;
    }

    $rootScope.$broadcast('filters.status', $scope.filters.status);

    var search = $location.search();
    search.status = $scope.filters.status;
    $location.search(search);
  };

  $scope.$watch('filters.code', function(code) {
    $rootScope.$broadcast('filters.code', code);
  });

  $scope.$watch('filters.bookingReference', function(bookingReference) {
    $rootScope.$broadcast('filters.bookingReference', bookingReference);
  });

  $scope.$watch('filters.email', function(email) {
    $rootScope.$broadcast('filters.email', email);
  });
}
